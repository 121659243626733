import styles from "./Loading.module.css"
import rstyles from "../Register/Register.module.css"
import logo from '../../assets/images/ehDev_logo.png'

const Loading = () => {
	return (
		<div className={rstyles.background}>
			<img className={rstyles.logo} src={logo}/>

			<div className={rstyles.background_img}></div>

			<div className={rstyles.registerContainer}>
				<div className={styles.loadpage_container}>
					<div className={styles.load}>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loading;