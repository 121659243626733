import { useEffect, useState, useRef, useContext, createContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import logo from '../../assets/images/ehDev_logo.png'
import thankyou from '../../assets/images/thankyou.svg'
import styles from "./Register.module.css"
import Forbidden from '../Forbidden';
import Loading from '../Loading';


const ProgressBar = () => {
	const { registerPage, setRegisterPage } = useContext(RegisterDataContext);

	return (
		<div className={styles.progressContainer}>
			<button disabled={registerPage === 7 || registerPage <= 1} onClick={() => setRegisterPage(1)} className={`${styles.progressCircle} ${registerPage === 1 ? styles.progressActive : registerPage > 1 ? styles.progressPrev : ""}`}><i className="fa-solid fa-user-tie"></i></button>
			<button disabled={registerPage === 7 || registerPage <= 2} onClick={() => setRegisterPage(2)} className={`${styles.progressCircle} ${registerPage === 2 ? styles.progressActive : registerPage > 2 ? styles.progressPrev : ""}`}><i className="fa-solid fa-microscope"></i></button>
			<button disabled={registerPage === 7 || registerPage <= 3} onClick={() => setRegisterPage(3)} className={`${styles.progressCircle} ${registerPage === 3 ? styles.progressActive : registerPage > 3 ? styles.progressPrev : ""}`}><i className="fa-solid fa-hammer"></i></button>
			<button disabled={registerPage === 7 || registerPage <= 4} onClick={() => setRegisterPage(4)} className={`${styles.progressCircle} ${registerPage === 4 ? styles.progressActive : registerPage > 4 ? styles.progressPrev : ""}`}><i className="fa-solid fa-desktop"></i></button>
			<button disabled={registerPage === 7 || registerPage <= 5} onClick={() => setRegisterPage(5)} className={`${styles.progressCircle} ${registerPage === 5 ? styles.progressActive : registerPage > 5 ? styles.progressPrev : ""}`}><i className="fa-solid fa-database"></i></button>
			<button disabled={registerPage === 7 || registerPage <= 6} onClick={() => setRegisterPage(6)} className={`${styles.progressCircle} ${registerPage === 6 ? styles.progressActive : registerPage > 6 ? styles.progressPrev : ""}`}><i className="fa-solid fa-comments-dollar"></i></button>

			<span className={styles.progressOutline}></span>
		</div>
	)
}

const InputContainer = ({ labelText, isTextArea, placeholder=null, moreTextHeight=false, lessTextHeight=false, noResize=false }) => {
	const { registerData, handleForm, fields, setFields, registerPage } = useContext(RegisterDataContext);
  	const labelRef = useRef(null);
  	const textareaRef = useRef(null);

  	useEffect(() => {
    	if (labelRef.current && textareaRef.current) {
      		const labelHeight = labelRef.current.offsetHeight;
      		textareaRef.current.style.paddingTop = `${labelHeight - 10}px`;
    	}

	    const updateFields = () => {
	      	setFields(prevFields => {
	        	const newFields = { ...prevFields };
	        	const pageFields = newFields[registerPage.toString()] || [];

	        	if (!pageFields.includes(labelText)) {
	          		pageFields.push(labelText);
	        	}
	        	
	        	newFields[registerPage.toString()] = pageFields;
	        	return newFields;
	      	});
	    };

	    updateFields()
  	}, [labelText, registerPage, setFields]);

  	return (
  		<>
	  		{isTextArea
	  			?
					<div className={styles.inputContainer}>
						<p ref={labelRef} className={styles.inputLabel}>{labelText}</p>
						<textarea ref={textareaRef} type="text" onChange={(input) => handleForm('set', labelText, input)} value={registerData[labelText]} className={`${styles.inputBox} ${styles.textAreaBox} ${moreTextHeight && styles.moreTextAreaHeight} ${lessTextHeight && styles.lessTextHeight} ${noResize && styles.noResize}`} placeholder={placeholder && placeholder}></textarea>
					</div>
	  			:
					<div className={styles.inputContainer}>
						<p ref={labelRef} className={styles.inputLabel}>{labelText}</p>
						<input ref={textareaRef} type="text" onChange={(input) => handleForm('set', labelText, input)} value={registerData[labelText]} className={styles.inputBox} placeholder={placeholder && placeholder}/>
					</div>
	  		}
  		</>
  	);
};


const RegistrationPage = ({ registerPage, handleForm, registerData, handleCloseValidation, validationError, setCountdown, countdown }) => {
  	useEffect(() => {
    	if (countdown > 0) {
      		const timer = setInterval(() => {
        		setCountdown(prevCountdown => prevCountdown - 1);
      		}, 1000);

      		return () => clearInterval(timer);
    	}
  	}, [countdown]);

	return (
		<div className={styles.background}>
			<img className={styles.logo} src={logo}/>
			<div className={styles.background_img}></div>

			<div className={styles.registerContainer}>
				{validationError &&
					<div className={`${styles.valError} ${styles.valErrorVisible}`}>
						<p>{validationError} ({countdown}s)</p>
						<button onClick={handleCloseValidation} className={styles.valClose}>X</button>
					</div>
				}

				{registerPage === 1 &&
					<>
						<div className={styles.registerHeader}>
							<div className={styles.registerHeaderContent}>
								<p className={styles.registerTitle}>Company Overview</p>
								<p className={styles.registerSubTitle}>Please provide all the required information below.</p>
							</div>

							<ProgressBar />
						</div>
						
						<span className={styles.breakLine}></span>

						<div className={styles.formContainer}>
							<InputContainer isTextArea={false} labelText="Company name*" prefill={registerData.company}/>
							<InputContainer isTextArea={false} labelText="Industry*" />
							<InputContainer isTextArea={false} labelText="No. employees*" />

							<InputContainer isTextArea={true} labelText="What is your business model?" placeholder="eg: B2B, B2C, SaaS"/>
							<InputContainer isTextArea={true} labelText="What are your business' primary activies?*"/>
							<InputContainer isTextArea={true} labelText="Who are your primary customers or clients?*"/>
						</div>
					</>
				}

				{registerPage === 2 &&
					<>
						<div className={styles.registerHeader}>
							<div className={styles.registerHeaderContent}>
								<p className={styles.registerTitle}>Project Scope and Objectives</p>
								<p className={styles.registerSubTitle}>Please provide all the required information below.</p>
							</div>

							<ProgressBar />
						</div>
						
						<span className={styles.breakLine}></span>

						<div className={`${styles.formContainer} ${styles.autoRows}`}>
							<InputContainer isTextArea={true} labelText="What are the main goals for this application?*" moreTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="What specific problems are you aiming to solve with this application?*" moreTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="What is the target audience for the application?*" placeholder="eg: internal employees, external clients, both" moreTextHeight={true}/>
						</div>
					</>
				}

				{registerPage === 3 &&
					<>
						<div className={styles.registerHeader}>
							<div className={styles.registerHeaderContent}>
								<p className={styles.registerTitle}>Functional Requirements</p>
								<p className={styles.registerSubTitle}>Please provide all the required information below.</p>
							</div>

							<ProgressBar />
						</div>
						
						<span className={styles.breakLine}></span>

						<div className={`${styles.formContainer}`}>
							<InputContainer isTextArea={true} labelText="How do you currently manage tasks within your projects?*" lessTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="Can you describe the workflow for creating, assigning, and tracking tasks?*" lessTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="What core features do you want in the application?*" placeholder="eg: task management, time tracking, resource allocation, Gantt charts" lessTextHeight={true}/>

							<InputContainer isTextArea={true} labelText="What types of users will the application have?*" placeholder="eg: admins, project managers, team members, clients" lessTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="Do you require features for team collaboration?*" placeholder="eg: comments on tasks, file sharing, real-time chat" lessTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="What types of reports do you need?*" placeholder="eg: project status, resource utilization, time logs" lessTextHeight={true}/>
						</div>
					</>
				}

				{registerPage === 4 &&
					<>
						<div className={styles.registerHeader}>
							<div className={styles.registerHeaderContent}>
								<p className={styles.registerTitle}>Technical Requirements</p>
								<p className={styles.registerSubTitle}>Please provide all the required information below.</p>
							</div>

							<ProgressBar />
						</div>
						
						<span className={styles.breakLine}></span>

						<div className={`${styles.formContainer}`}>
							<InputContainer isTextArea={false} labelText="Should the application be web-based, mobile-based, or both*" />
							<InputContainer isTextArea={false} labelText="If mobile-based, do you need apps for iOS, Android, or both" />
							<InputContainer isTextArea={false} labelText="Are there any specific compliance requirements" placeholder="eg: GDPR, HIPAA"/>

							<InputContainer isTextArea={true} labelText="What existing systems or tools should this application integrate with?*" placeholder="eg: CRM, accounting software, email services"/>
							<InputContainer isTextArea={true} labelText="What are your security requirements?" placeholder="eg: data encryption, user authentication, access control"/>
							<InputContainer isTextArea={true} labelText="Should the application provide APIs for external access?"/>
						</div>
					</>
				}

				{registerPage === 5 &&
					<>
						<div className={styles.registerHeader}>
							<div className={styles.registerHeaderContent}>
								<p className={styles.registerTitle}>Data Management</p>
								<p className={styles.registerSubTitle}>Please provide all the required information below.</p>
							</div>

							<ProgressBar />
						</div>
						
						<span className={styles.breakLine}></span>

						<div className={`${styles.formContainer}`}>
							<InputContainer isTextArea={true} labelText="What type of data will the application manage and store?*" noResize={true}/>
							<InputContainer isTextArea={true} labelText="Do you have any preferences for data storage solutions?" placeholder="eg: cloud-based, on-premises" noResize={true}/>
							<InputContainer isTextArea={true} labelText="Is there existing data that needs to be migrated to the new system?*" noResize={true}/>
							<InputContainer isTextArea={true} labelText="If so, what is the volume and format of this data?" noResize={true}/>
						</div>
					</>
				}

				{registerPage === 6 &&
					<>
						<div className={styles.registerHeader}>
							<div className={styles.registerHeaderContent}>
								<p className={styles.registerTitle}>Budget and Timeline</p>
								<p className={styles.registerSubTitle}>Please provide all the required information below.</p>
							</div>

							<ProgressBar />
						</div>
						
						<span className={styles.breakLine}></span>

						<div className={`${styles.formContainer} ${styles.autoRows}`}>
							<InputContainer isTextArea={true} labelText="What is your defined/maximum budget for this project?*" moreTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="What is your desired timeline for project milestones and final delivery?*" moreTextHeight={true}/>
							<InputContainer isTextArea={true} labelText="Do you require a phased rollout of features, and if so, what features should be included in each phase?" moreTextHeight={true}/>
						</div>
					</>
				}

				<div className={styles.controls}>
					{registerPage !== 1 && <button className={styles.backBtn} disabled={validationError} onClick={() => handleForm('back')}><p>Back</p></button>}
					{registerPage !== 6 && <button className={styles.nextBtn} disabled={validationError} onClick={() => handleForm('next')}><p>Next and save</p></button>}
					{registerPage === 6 && <button className={styles.nextBtn} disabled={validationError} onClick={() => handleForm('submit')}><p>Submit</p></button>}
				</div>

			</div>

		</div>
	)
}

const Submitted = () => {
	return (
		<div className={styles.background}>
			<img className={styles.logo} src={logo}/>

			<div className={styles.background_img}></div>

			<div className={styles.registerContainer}>
				<div className={styles.registerHeader}>
					<div className={styles.registerHeaderContent}>
						<p className={styles.registerTitle}>Client Questionnaire</p>
						<p className={styles.registerSubTitle}>Thank you for providing all the information required.</p>
					</div>

					<ProgressBar />
				</div>

				<span className={styles.breakLine}></span>

				<div className={styles.thankYouContainer}>
					<img className={styles.thankYouImg} src={thankyou}/>
					<p className={`${styles.registerTitle} ${styles.thankYouMessage}`}>Thank you for your submission. Please expect a detailed response very soon!</p>
				</div>
			</div>
		</div>
	)
}


const RegisterDataContext = createContext();

const Register = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const [validationError, setValidationError] = useState(null);
	const [registerPage, setRegisterPage] = useState(1);
	const [registerData, setRegisterData] = useState({});
	const [isCompleted, setIsCompleted] = useState(false)
	const [fields, setFields] = useState({})
	const [countdown, setCountdown] = useState(0);


	const param = useParams();
	const url = `/api/register/${param._id}`;

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const intialRegister = await axios.get(url)

					if (intialRegister.data.form && Object.keys(intialRegister.data.form).length > 0) {
						if (intialRegister.data.completed_date) {
							setIsCompleted(true)
							setRegisterPage(7)
						} else {
							setRegisterData(intialRegister.data.form)
						}
					} else {
						setRegisterData({'Company name*': intialRegister.data.company})
					}

					setIsAuthenticated(true)
				} catch(e) {
					console.log(e)
					setIsAuthenticated(false);
				}

				setIsLoading(false)
			} catch (error) {
				console.log(error)
			}
		};
		initLoad();
	}, [param, url]);


  	const triggerValidationError = (message) => {
    	setValidationError(message);
    	setCountdown(5);

    	setTimeout(() => {
      		setValidationError(null);
      		setCountdown(0);
    	}, 5900);
  	}

	const handleForm = async (control, name=null, input=null) => {
		switch (control) {
			case 'back':
				setRegisterPage(registerPage - 1)
				break;
			case 'next':
				if (Object.keys(fields).length > 0) {
					for (let key of fields[registerPage.toString()]) {
						if (key.includes('*') && (!registerData.hasOwnProperty(key) || registerData[key] === "")) {
							if (!validationError) {
								triggerValidationError(`Please enter a value for ${key}`)
						    	return false
							}
						}
					}
				} else {
					return false
				}

				setValidationError(null)
				setIsLoading(true)
				await axios.post(url, { form: registerData, completed: false })
				setRegisterPage(registerPage + 1)
				setIsLoading(false)
				break;
			case 'set':
				let registerDataDup = { ...registerData }
				registerDataDup[`${name}`] = input.target.value

				setRegisterData(registerDataDup)
				break;
			case 'submit':
				if (Object.keys(fields).length > 0) {
					for (let key of fields[registerPage.toString()]) {
						if (key.includes('*') && (!registerData.hasOwnProperty(key) || registerData[key] === "")) {
							if (!validationError) {
								triggerValidationError(`Please enter a value for ${key}`)
						    	return false
							}
						}
					}
				} else {
					return false
				}

				setValidationError(null)
				setIsLoading(true)
				await axios.post(url, { form: registerData, completed: true })
				setRegisterPage(7)
				setIsCompleted(true)
				setIsLoading(false)
				break;
		}
	}

	const handleCloseValidation = () => {
		setValidationError(null)
	}


	return (
		<>			
			{isLoading
				? <Loading />
				:
					<>
						{isAuthenticated
							?
								<RegisterDataContext.Provider value={{ registerData, setRegisterData, handleForm, registerPage, setRegisterPage, fields, setFields }}>
									{isCompleted
										? <Submitted />
										: <RegistrationPage {...{ registerPage, handleForm, registerData, handleCloseValidation, validationError, setCountdown, countdown }} />
											 
									}
								</RegisterDataContext.Provider>
							: <Forbidden />
						}
					</>
			}
		</>
	);
};

export default Register;