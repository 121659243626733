import { Route, Routes, Navigate } from "react-router-dom";

import Register from "./components/Register";


function App() {
	// const account = localStorage.getItem("token");

	return (
		<Routes>
			{/* <Route path="/login" exact element={<Login />} /> */}
			<Route path="/register/:_id" exact element={<Register />} />

			{/* <Route path="/" element={<Navigate replace to="/login" />} /> */}
		</Routes>
	);
}

export default App;
