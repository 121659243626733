import { useEffect, useState, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import fstyles from "./Forbiddenstyles.module.css"
import logo from '../../assets/images/ehDev_logo.png'


const Forbidden = () => {
	return (
		<div style={{height: '100vh', width: '100%', position: 'relative'}}>

			<img className={fstyles.logo} src={logo}/>

			<div style={{height: '100%', width: '100%', 'display': 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<div className={fstyles.hover}>
				  <div className={fstyles.background}>
				    <div className={fstyles.door}><p className={fstyles.forbidden_1}>403</p><p className={fstyles.forbidden_text}>Access Denied</p></div>
				    <div className={fstyles.rug}></div>
				  </div>
				  <div className={fstyles.foreground}>
				    <div className={fstyles.bouncer}>
				      <div className={fstyles.head}>
				        <div className={fstyles.neck} />
				        <div className={`${fstyles.eye} ${fstyles.left}`} />
				        <div className={`${fstyles.eye} ${fstyles.right}`} />
				        <div className={fstyles.ear} />
				      </div>
				      <div className={fstyles.body} />
				      <div className={fstyles.arm} />
				    </div>
				    <div className={fstyles.poles}>
				      <div className={`${fstyles.pole} ${fstyles.left}`} />
				      <div className={`${fstyles.pole} ${fstyles.right}`} />
				      <div className={fstyles.rope} />
				    </div>
				  </div>
				</div>

			</div>
		</div>
	)
};

export default Forbidden;
